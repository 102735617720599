import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import FormCreateUpdateTH from "@/view/pages/components/surat_jalan/create-update.vue";

export default {
  components: {
    FormCreateUpdateTH
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentYear"
    ]),
  },

  data() {
    return {
      // CODE
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Transaksi", route: "/" },
      { title: "Surat Jalan", route: "/transactions/surat_jalan/list" },
      { title: "Tambah Surat Jalan" }
    ])

  },
  
};